import '@fancyapps/fancybox';
import 'imagesloaded/imagesloaded.pkgd.js';
import 'isotope-layout/dist/isotope.pkgd.js';
import 'isotope-packery/packery-mode.pkgd.js';

$(document).ready(function () {
    initiatePackery();

    if (document.referrer.indexOf(window.location.host) !== -1) {
        $('.js-back-container').addClass('d-sm-flex');        
    }

    $('.js-back').on('click', function(evt) { 
        evt.preventDefault(); history.back(); 
    });
    
    //Not to close the dropdown container on a click on a label. 
    //This allows the close on a click outside of the container
    $(document).on('click.bs.dropdown.data-api', '.dropdown .keep-open-on-click', (event) => {
        event.stopPropagation();
    });

    if ($('#show-mid-screen-scroll').length > 0) {

        $(window).scroll(function () {

            var element = $('#show-mid-screen-scroll:first');
            
            var top = parseInt($(window).scrollTop());
            //if scroll > window height / 2 , remove d-none from element
            if (top > 50 && element.hasClass('hide')) {
                element[0].classList.remove('hide');
            }
            
            if (top < 50 && !element.hasClass('hide')) {
                element[0].classList.add('hide');
            }
        });
    }

    if ($('.sticky-element-top').length > 0) {
        //we should have only 1 top sticky element
        var element = $('.sticky-element-top:first');
        var elementParent = element.parent()[0];
        var stop = (elementParent.getBoundingClientRect()).top + window.scrollY;
        
        window.addEventListener('scroll', function(e) {
            checkStickyPosition(element[0], stop);
        });
        
        window.addEventListener('resize', function(e) {
            //get parent element position again since on resize the contents position change
            stop = (elementParent.getBoundingClientRect()).top + window.scrollY;
            
            checkStickyPosition(element[0], stop);
        });
    }

    $(document).on('click', '.dropdown-submenu a.dropdown-toggle', function() { 
        if (!$(this).next().hasClass('show')) {
            $(this).parents('.dropdown-menu').first().find('.show').removeClass("show");
        }

        var $subMenu = $(this).next(".dropdown-menu");
        $subMenu.toggleClass('show');

        $(this).parents('nav-item.dropdown.show').on('hidden.bs.dropdown', function(e) {
            $('.dropdown-submenu .show').removeClass("show");
        });

        return false;
    });

});

function checkStickyPosition(element, stop) {
    // var stop = (element.getBoundingClientRect()).top + window.scrollY;
    var scrollTop = (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop;
    
    if (scrollTop >= stop && top != 0) {
        element.classList.add('sticky');
    } else {
        element.classList.remove('sticky');
    }
}

function initiatePackery() {
  var $grid = $('.bricks').imagesLoaded( function() {
  // init Packery after all images have loaded
    $grid.packery({
      itemSelector: '.grid-item',
      percentPosition: true,
      gutter: '.gutter-sizer',
    });
    jQuery('.bricks').addClass('loaded');
  });  
}